<script>
import LogoSvg from "@/components/Landing/LogoSvg.vue";
import {required, email} from "vuelidate/lib/validators";
import {
	authMethods,
	notificationMethods,
} from "@/state/helpers";

export default {
	components: {LogoSvg},
	data() {
		return {
			email: "",
			submitted: false,
		};
	},
	validations: {
		email: {required, email},
	},
	computed: {
		notification() {
			return this.$store ? this.$store.state.notification : null;
		},
		notificationAutoCloseDuration() {
			return this.$store && this.$store.state.notification ? 5 : 0;
		},
	},

	methods: {
		...authMethods,
		...notificationMethods,
		tryToVerificate() {
			this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			} else {
				this.sendVerificationEmail({
					email: this.email,
				})
			}
		},
	}
}
</script>

<template>
	<div class="account-pages my-5 pt-sm-5">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 col-xl-5">
					<div class="text-center mb-4">
						<router-link to="/" class="auth-logo mb-5 d-block">
							<LogoSvg/>
						</router-link>
						<h4>Email verification</h4>
					</div>
					<div class="card">
						<div class="card-body p-4">
							<b-alert
								:variant="notification.type"
								class="mt-3"
								v-if="notification.message"
								:show="notificationAutoCloseDuration"
								dismissible
							>{{ notification.message }}
							</b-alert>
							<div class="p-3">
								<form @submit.prevent="tryToVerificate">
									<div class="form-group">
										<label>{{ $t('login.form.email.label') }}</label>
										<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
											<div class="input-group-prepend">
                        <span class="input-group-text border-light text-muted">
                          <i class="ri-mail-line"></i>
                        </span>
											</div>
											<input
												v-model="email"
												type="email"
												class="form-control bg-soft-light border-light"
												:class="{ 'is-invalid': submitted && $v.email.$error }"
												:placeholder="$t('login.form.email.placeholder')"
											/>
											<div v-if="submitted && $v.email.$error" class="invalid-feedback">
                        <span
							v-if="!$v.email.required"
						>{{ $t('login.form.email.validation.required') }}</span>
												<span v-if="!$v.email.email">{{
														$t('login.form.email.validation.invalid')
													}}</span>
											</div>
										</div>
									</div>
									<div>
										<button
											class="btn btn-primary btn-block"
											type="submit"
										>Send email
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>

					<div class="mt-5 text-center">
						<p>
							{{ $t('register.form.signintext') }}
							<router-link
								tag="a"
								to="/login"
								class="font-weight-medium text-primary"
							>{{ $t('register.form.signinlink') }}
							</router-link>
						</p>
						<p>
							© {{ new Date().getFullYear() }}
							<i
								class="mdi mdi-heart text-danger"
							></i>
							Romeo
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>